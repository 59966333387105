import { XMarkIcon } from "@heroicons/react/24/outline";
import BrandCategoryDropdown from "../brands/BrandCategoryDropdown";
import { Badge } from "../catalyst/badge";
import { Strong, Text } from "../catalyst/text";
import { Table, TableBody, TableHead, TableHeader, TableRow } from "../catalyst/table";
import { ChallengeTemplateComponent, ChallengeTemplateForm } from "../challenges/ChallengeTemplateComponent";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { CHALLENGE_CATEGORIES, ChallengeCategoriesInterface } from "../../graphql/queries";
import { BrandCategory, ChallengeCategory } from "@kalecard/common";
import { getAllBrandCategories } from "../../utils/brands";

export default function ChallengeThemes() {
  const [brandCategories, setBrandCategories] = useState<BrandCategory[]>([]);
  const [selectedBrandCategories, setSelectedBrandCategories] = useState<BrandCategory[]>([]);

  useEffect(() => {
    getAllBrandCategories().then((brandCategories) => {
      setBrandCategories(brandCategories);
    });
  }, []);

  const { data, loading, refetch } =
    useQuery<ChallengeCategoriesInterface>(CHALLENGE_CATEGORIES, {
      variables: {
        brandCategoryIds: selectedBrandCategories.map((brandCategory) => brandCategory.id)
      },
      skip: brandCategories.length === 0,
    });

  useEffect(() => {
    refetch();
  }, [selectedBrandCategories]);

  const removeBrandCategory = (category) => {
    const updatedBrandCategories = selectedBrandCategories.filter((brandCategory) => brandCategory.id !== category.id);
    setSelectedBrandCategories(updatedBrandCategories);
  }
  
  return (
    <div className="space-y-6">
      {/* Brand Category Filters */}
      <BrandCategoryFilters brandCategories={brandCategories} selectedBrandCategories={selectedBrandCategories} removeBrandCategory={removeBrandCategory} setSelectedBrandCategories={setSelectedBrandCategories}/>

      {/* Challenge Templates */}
      <ChallengeTemplatesTable challengeTemplates={data?.challengeCategories} brandCategories={brandCategories} />
    </div>
  );
}

function BrandCategoryFilters({ brandCategories, selectedBrandCategories, removeBrandCategory, setSelectedBrandCategories }) {
  return (
    <div className="space-y-2">
      <div className="flex space-x-2 items-center">
        <Text><Strong>Filter by Brand Category:</Strong></Text>
        <BrandCategoryDropdown 
          brandCategories={brandCategories} 
          selectedBrandCategories={selectedBrandCategories} 
          setSelectedBrandCategories={setSelectedBrandCategories}
        />
      </div>
      <div className="space-x-2 text-wrap space-y-1">
        {selectedBrandCategories.map((brandCategory) => (
          <Badge color="indigo" key={brandCategory.id}>
            {brandCategory.name}
            <XMarkIcon className="h-4 w-4 hover:cursor-pointer" onClick={() => removeBrandCategory(brandCategory)} />
          </Badge>
        ))}
      </div>
    </div>
  );
}

function ChallengeTemplatesTable({ challengeTemplates, brandCategories }: { challengeTemplates?: ChallengeCategory[], brandCategories?: BrandCategory[] }) {
  return (
    <Table striped className="border rounded-md p-4">
      <TableHead>
        <TableRow className="text-black">
          <TableHeader>Name</TableHeader>
          <TableHeader>Template</TableHeader>
          <TableHeader>Brand Categories</TableHeader>
          <TableHeader></TableHeader>
        </TableRow>
      </TableHead>

      <TableBody>
        {challengeTemplates && challengeTemplates.map((challengeTemplate) => (
          <ChallengeTemplateComponent key={challengeTemplate.id} challengeTemplate={challengeTemplate} brandCategories={brandCategories} />
        ))}

        {/* Table Row for New Challenge Template */}
        <ChallengeTemplateForm isEditForm={false} brandCategories={brandCategories} />
      </TableBody>
    </Table>
  );
}