import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../../catalyst/fieldset";
import { Input } from "../../catalyst/input";
import { Button } from "../../catalyst/button";
import { Badge } from "../../catalyst/badge";
import { Strong, Text } from "../../catalyst/text";
import BrandsDropdown from "../BrandsDropdown";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Brand, OptionInterface, Spinner } from "@kalecard/common";
import { useMutation } from "@apollo/client";
import { UPDATE_BRAND_CONTENT_INFO } from "../../../graphql/mutations";

interface BrandContentInfoInput {
  newRequiredHashtag: string;
  newKaleHashtag: string;
}

export default function BrandContentInfo({ brand }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const initializeBrandOptions = (
    brands: Brand[],
    setBrandOptions: React.Dispatch<React.SetStateAction<OptionInterface[]>>
  ) => {
    const options = brands.map((brand) => {
      return { id: brand.id, name: brand.name, imageUrl: brand.logoUrl };
    });
    setBrandOptions(options);
  };

  const [requiredHashtags, setRequiredHashtags] = useState<string[]>(
    brand.hashtagRequirements ?? []
  );
  const [kaleHashtags, setKaleHashtags] = useState<string[]>(
    brand.kaleHashtagRequirements ?? []
  );
  const [requiredMentions, setRequiredMentions] =
    useState<OptionInterface[]>(null);
  const [deniedBrands, setDeniedBrands] = useState<OptionInterface[]>(null);

  const [updateBrandContentInfo] = useMutation(UPDATE_BRAND_CONTENT_INFO);

  const { register, handleSubmit, getValues, reset } =
    useForm<BrandContentInfoInput>();

  const addHashtag = (
    hashtag: string,
    hashtagGroup: string[],
    setHashtagGroup: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const cleanHashtag = hashtag.trim();
    const hashtagWithSymbol =
      cleanHashtag[0] !== "#" ? "#" + cleanHashtag : cleanHashtag;

    const existingHashtag = hashtagGroup.find(
      (temp) => temp === hashtagWithSymbol
    );
    if (!existingHashtag) {
      setHashtagGroup([...hashtagGroup, hashtagWithSymbol]);
    }
    reset();
  };

  const removeHashtag = (
    hashtag: string,
    hashtagGroup: string[],
    setHashtagGroup: React.Dispatch<React.SetStateAction<string[]>>
  ) => {
    const updatedHashtags = hashtagGroup.filter((temp) => temp !== hashtag);
    setHashtagGroup(updatedHashtags);
  };

  const addBrandOption = (
    brandOption: OptionInterface,
    optionGroup: OptionInterface[],
    setOptionGroup: React.Dispatch<React.SetStateAction<OptionInterface[]>>
  ) => {
    const existingBrandOption = optionGroup.find(
      (option) => option.id === brandOption.id
    );
    if (!existingBrandOption) {
      setOptionGroup([...optionGroup, brandOption]);
    }
  };

  const removeBrandOption = (
    brandOption: OptionInterface,
    optionGroup: OptionInterface[],
    setOptionGroup: React.Dispatch<React.SetStateAction<OptionInterface[]>>
  ) => {
    const updatedBrandOptions = optionGroup.filter(
      (option) => option.id !== brandOption.id
    );
    setOptionGroup(updatedBrandOptions);
  };

  useEffect(() => {
    brand.mentionRequirements
      ? initializeBrandOptions(brand.mentionRequirements, setRequiredMentions)
      : setRequiredMentions([]);
    brand.deniedBrands
      ? initializeBrandOptions(brand.deniedBrands, setDeniedBrands)
      : setDeniedBrands([]);
  }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const variables = {
        brandId: brand.id,
        requiredHashtags: requiredHashtags,
        kaleHashtags: kaleHashtags,
        requiredMentions: requiredMentions.map((mention) => mention.id),
        deniedBrands: deniedBrands.map((brand) => brand.id),
      };

      await updateBrandContentInfo({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage("Congrats! Your changes have been applied.");
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form className="space-y-4" key="payments">
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Content Requirements</Legend>
        </div>
        <FieldGroup>
          <div className="flex justify-between space-x-10">
            <Field className="w-full space-y-2">
              <Label>Required Hashtags</Label>
              <Description>
                Only posts with these hashtags will be accepted.
              </Description>
              <div className="flex space-x-2">
                <Input
                  placeholder="#partner"
                  {...register("newRequiredHashtag")}
                  type="text"
                />
                <Button
                  color="emerald"
                  onClick={() =>
                    addHashtag(
                      getValues("newRequiredHashtag"),
                      requiredHashtags,
                      setRequiredHashtags
                    )
                  }
                >
                  Add
                </Button>
              </div>

              <div className="flex flex-wrap gap-2">
                {requiredHashtags.map((hashtag) => (
                  <Badge color="lime" className="space-x-1">
                    <Text>
                      <Strong>{hashtag}</Strong>
                    </Text>
                    <XMarkIcon
                      className="h-4 w-4 text-gray-500"
                      onClick={() =>
                        removeHashtag(
                          hashtag,
                          requiredHashtags,
                          setRequiredHashtags
                        )
                      }
                    />
                  </Badge>
                ))}
              </div>
            </Field>

            <Field className="w-full space-y-2">
              <Label>Kale-Specific Hashtags</Label>
              <Description>
                These non-required hashtags will be added to new challenges.
              </Description>
              <div className="flex space-x-2">
                <Input
                  placeholder="#partner"
                  {...register("newKaleHashtag")}
                  type="text"
                />
                <Button
                  color="emerald"
                  onClick={() =>
                    addHashtag(
                      getValues("newKaleHashtag"),
                      kaleHashtags,
                      setKaleHashtags
                    )
                  }
                >
                  Add
                </Button>
              </div>

              <div className="flex flex-wrap gap-2">
                {kaleHashtags.map((hashtag) => (
                  <Badge color="lime" className="space-x-1">
                    <Text>
                      <Strong>{hashtag}</Strong>
                    </Text>
                    <XMarkIcon
                      className="h-4 w-4 text-gray-500"
                      onClick={() =>
                        removeHashtag(hashtag, kaleHashtags, setKaleHashtags)
                      }
                    />
                  </Badge>
                ))}
              </div>
            </Field>
          </div>

          <div className="flex justify-between space-x-10">
            <Field className="w-full space-y-2">
              <Label>Required Mentions</Label>
              <BrandsDropdown
                brandId={""}
                setBrand={(option) =>
                  addBrandOption(option, requiredMentions, setRequiredMentions)
                }
                setBrandId={() => null}
              />
              <Description>
                Only post that mention the following brand(s) will be accepted.
              </Description>

              <div className="flex flex-wrap gap-2">
                {requiredMentions?.map((mentionRequirement) => (
                  <Badge
                    key={mentionRequirement.id}
                    color="lime"
                    className="space-x-1"
                  >
                    <Text>
                      <Strong>{mentionRequirement.name}</Strong>
                    </Text>
                    <XMarkIcon
                      className="h-4 w-4 text-gray-500"
                      onClick={() =>
                        removeBrandOption(
                          mentionRequirement,
                          requiredMentions,
                          setRequiredMentions
                        )
                      }
                    />
                  </Badge>
                ))}
              </div>
            </Field>

            <Field className="w-full space-y-2">
              <Label>Blocked Brands</Label>
              <BrandsDropdown
                brandId={""}
                setBrand={(option) =>
                  addBrandOption(option, deniedBrands, setDeniedBrands)
                }
                setBrandId={() => null}
              />
              <Description>
                The following brand(s) will not show up on the earn tab for the
                current brand's creators.
              </Description>

              <div className="flex flex-wrap gap-2">
                {deniedBrands?.map((deniedBrand) => (
                  <Badge
                    key={deniedBrand.id}
                    color="lime"
                    className="space-x-1"
                  >
                    <Text>
                      <Strong>{deniedBrand.name}</Strong>
                    </Text>
                    <XMarkIcon
                      className="h-4 w-4 text-gray-500"
                      onClick={() =>
                        removeBrandOption(
                          deniedBrand,
                          deniedBrands,
                          setDeniedBrands
                        )
                      }
                    />
                  </Badge>
                ))}
              </div>
            </Field>
          </div>
        </FieldGroup>
      </Fieldset>
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button color="indigo" onClick={handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}
