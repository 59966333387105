import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../../catalyst/fieldset";
import { Input } from "../../catalyst/input";
import { Button } from "../../catalyst/button";
import { Strong, Text } from "../../catalyst/text";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../catalyst/dropdown";
import {
  ChevronDownIcon,
  EllipsisVerticalIcon,
} from "@heroicons/react/24/outline";
import { Brand, Spinner } from "@kalecard/common";
import { useMutation } from "@apollo/client";
import { UPDATE_BRAND_ADMINS_AND_ROLES } from "../../../graphql/mutations";

interface BrandAdmin {
  name?: string;
  email: string;
  role?: string;
}

interface NewBrandAdminInput {
  newBrandAdminEmail: string;
}

export default function BrandAdmins(props: { brand: Brand }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const initializeBrandAdmins = (admins) => {
    const initializedAdmins = admins.map((admin) => {
      const administeredBrand = admin.administeredBrands?.find(
        (administeredBrand) => administeredBrand.brand?.id === props.brand.id
      );
      return {
        email: admin.email,
        name: admin.name,
        role: administeredBrand.role,
      };
    });

    return initializedAdmins;
  };

  const [brandAdmins, setBrandAdmins] = useState<BrandAdmin[]>([]);

  const [updateBrandAdminsAndRoles] = useMutation(
    UPDATE_BRAND_ADMINS_AND_ROLES
  );

  useEffect(() => {
    const admins = props.brand.admins;
    setBrandAdmins(admins ? initializeBrandAdmins(admins) : []);
  }, [props.brand, setBrandAdmins]);

  const { register, handleSubmit, getValues, reset } =
    useForm<NewBrandAdminInput>();

  const handleNewBrandAdmin = (email) => {
    const existingAdmin = brandAdmins.find((admin) => admin.email === email);
    if (!existingAdmin) {
      setBrandAdmins([...brandAdmins, { email: email, role: "BRAND_ADMIN" }]);
    }
    reset();
  };

  const handleRemoveAdmin = (admin: BrandAdmin) => {
    const updatedAdmins = brandAdmins.filter(
      (brandAdmin) => brandAdmin.email !== admin.email
    );
    setBrandAdmins(updatedAdmins);
  };

  const setAdminRole = (admin: BrandAdmin, role: string) => {
    const updatedAdmins = brandAdmins.map((brandAdmin) => {
      if (brandAdmin.email === admin.email) {
        return { ...brandAdmin, role: role };
      } else {
        return brandAdmin;
      }
    });
    setBrandAdmins(updatedAdmins);
  };

  const onlyKaleAdmins = () => {
    return (
      brandAdmins.find((admin) => !admin.email.includes("@kalecard.com")) ==
      null
    );
  };

  const onSubmit = async () => {
    if (brandAdmins.length == 0 || onlyKaleAdmins()) {
      setErrorMessage(
        "Brand must have at least one admin that's not a Kale employee."
      );
      clearErrorMessage();
      return;
    }

    if (brandAdmins.find((admin) => !admin.role) != null) {
      setErrorMessage("Please assign a role to every admin.");
      clearErrorMessage();
      return;
    }

    setLoading(true);

    const adminsInput = brandAdmins.map((admin) => {
      return { email: admin.email, role: admin.role };
    });
    try {
      const variables = {
        brandId: props.brand.id,
        admins: adminsInput,
      };

      await updateBrandAdminsAndRoles({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage("Congrats! Your changes have been applied.");
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form className="space-y-4" key="admins">
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Brand Admins</Legend>
        </div>
        <FieldGroup>
          <Field>
            <Label>Add a new brand admin</Label>
            <div className="flex space-x-2">
              <Input
                placeholder="you@example.com"
                {...register("newBrandAdminEmail")}
                type="email"
              />
              <Button
                color="emerald"
                onClick={() =>
                  handleNewBrandAdmin(getValues("newBrandAdminEmail"))
                }
              >
                Add
              </Button>
            </div>
          </Field>
        </FieldGroup>
      </Fieldset>
      {brandAdmins.length > 0 && (
        <div className="grid grid-cols-2 gap-x-20 gap-y-4 rounded-md bg-gray-100 p-2">
          {brandAdmins.map((brandAdmin) => (
            <div
              key={brandAdmin.email}
              className="flex items-center justify-between"
            >
              {/* Email and name */}
              <div>
                <Text>
                  <Strong>{brandAdmin.email}</Strong>
                </Text>
                <Text>{brandAdmin.name}</Text>
              </div>

              {/* Role and menu */}
              <div className="flex items-center space-x-2">
                <Dropdown>
                  <DropdownButton plain>
                    <EllipsisVerticalIcon />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem onClick={() => handleRemoveAdmin(brandAdmin)}>
                      Remove Admin
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown>
                  <DropdownButton
                    color="white"
                    aria-label="More options"
                    defaultValue={brandAdmin.role}
                  >
                    <p className="text-sm font-normal">
                      {brandAdmin.role
                        ? brandAdmin.role === "BRAND_OWNER"
                          ? "POC"
                          : "Admin"
                        : "Select a role"}
                    </p>
                    <ChevronDownIcon className={"w-8"} />
                  </DropdownButton>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => setAdminRole(brandAdmin, "BRAND_ADMIN")}
                      value="BRAND_ADMIN"
                    >
                      Admin
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => setAdminRole(brandAdmin, "BRAND_OWNER")}
                      value="BRAND_OWNER"
                    >
                      POC
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button color="indigo" onClick={handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export function isBrandAdminsStepComplete(brand: Brand): boolean {
  const atLeastOneAdmin = brand.admins?.length > 0;
  const allAdminsWithRoles =
    brand.admins?.find((admin) => {
      const adminBrand = admin.administeredBrands.find(
        (adminBrand) => adminBrand.brand?.id === brand.id
      );
      return !adminBrand.role;
    }) == null;

  return atLeastOneAdmin && allAdminsWithRoles;
}
