import { ChallengeTemplate, Spinner } from "@kalecard/common";
import { useState } from "react";
import { DEACTIVATE_CHALLENGE_TEMPLATE } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { CHALLENGE_TEMPLATES } from "../../graphql/queries";
import { Heading } from "../catalyst/heading";
import { Strong, Text } from "../catalyst/text";
import { Button } from "../catalyst/button";

export default function DeactivateChallengeTemplateForm({ challengeTemplate, onSubmit } : { challengeTemplate: ChallengeTemplate, onSubmit: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [deactivateChallengeTemplate] = useMutation(DEACTIVATE_CHALLENGE_TEMPLATE);

  const submit = async () => {
    setIsLoading(true);
    try {
      const result = await deactivateChallengeTemplate({
        variables: {
         challengeTemplateId: challengeTemplate.id
        },
        refetchQueries: [CHALLENGE_TEMPLATES],
      });
      console.log(result);
      onSubmit();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="space-y-2">
      {/* Header */}
      <div className="flex justify-end">
        <Text>{challengeTemplate.title}</Text>
      </div>
      <div className="flex flex-row space-x-2 justify-center items-center capitalize">
        <Heading>Heads up!</Heading>
      </div>

      <div>
        <Text><Strong>Deactivating this {challengeTemplate.templateType.toLowerCase()} will:</Strong></Text>
        <ol className="list-decimal pl-4">
          <Text><li>Expire all pending challenge requests for this {challengeTemplate.templateType.toLowerCase()}.</li></Text>
          <Text><li>Turn off challenges with this {challengeTemplate.templateType.toLowerCase()} that have been live for at least a week.</li></Text>
          <Text><li>Set a deactivation date for challenges with this {challengeTemplate.templateType.toLowerCase()} that haven't been live for a week.</li></Text>
        </ol>
      </div>

      <div className="flex justify-end items-center space-x-4">
        {isLoading && (<Spinner size="h-5 w-5" />)}
        <Button color="emerald" onClick={submit}>
          Deactivate
        </Button>
      </div>
    </form>
  );
}