import { useMutation } from "@apollo/client";
import { Brand, Challenge, OptionInterface, Spinner } from "@kalecard/common";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { UPDATE_CHALLENGE } from "../../graphql/mutations";
import { SIMPLE_BRANDS } from "../../graphql/queries";
import { getAllBrandCategories, getAllBrands } from "../../utils/brands";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../catalyst/button";
import {
  ChallengeFormDataComponent,
  getRoundedDate,
} from "../challenges/SharedChallengeComponents";

type EditChallengeFormInput = {
  description: string;
  audienceType: string;
  newCreatorId: string;
  exampleUrl: string;
  newTag: string;
  title?: string;
};

interface EditChallengeFormInterface {
  challenge: Challenge;
  onSubmit?: (challenge: Challenge) => void;
}

export default function EditChallengeForm({
  challenge,
  onSubmit,
}: EditChallengeFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { register, handleSubmit, watch, getValues, setValue } =
    useForm<EditChallengeFormInput>({
      defaultValues: {
        description: challenge.description,
        audienceType: challenge.audienceType,
        exampleUrl: challenge.exampleUrl,
        title: challenge.title,
      },
    });
  const [difficulty, setDifficulty] = useState(
    !challenge.difficulty || challenge.difficulty === 0
      ? 1
      : challenge.difficulty
  );
  const [brands, setBrands] = useState<Brand[]>([]);

  const getInitialChallengePostRequirements = () => {
    const brandRequirements = challenge?.brand.postRequirements ?? [];
    return (
      challenge.postRequirements?.filter(
        (postRequirement) =>
          !brandRequirements.some(
            (brandReq) => brandReq.id === postRequirement.id
          )
      ) ?? []
    );
  };

  const [brandCategories, setBrandCategories] = useState<OptionInterface[]>([]);
  // const [showAudience, setShowAudience] = useState<boolean>(false);
  const [brandAudience, setBrandAudience] = useState<OptionInterface[]>([]);
  const [selectedBrandProduct, setSelectedBrandProduct] = useState(
    challenge.requiredBrandProducts?.[0]
  );
  const [brandCategoriesAudience, setBrandCategoriesAudience] = useState<
    OptionInterface[]
  >([]);
  const [creatorsAudience, setCreatorsAudience] = useState<Set<string>>(
    new Set()
  );
  const [tags, setTags] = useState<Set<string>>(
    new Set(challenge.tagRequirements && challenge.tagRequirements.split(" "))
  );
  const [startDate, setStartDate] = useState<Date>(
    challenge.startDate ? new Date(Number(challenge.startDate)) : null
  );
  const [endDate, setEndDate] = useState<Date>(
    challenge.endDate ? new Date(Number(challenge.endDate)) : null
  );
  const [isCommercialSoundsOnly, setIsCommercialSoundsOnly] = useState(
    challenge.isCommercialSoundsOnly === true
  );
  const [challengeCategory, setChallengeCategory] = useState(
    challenge.category
  );

  const [challengePostRequirements, setChallengePostRequirements] = useState(
    getInitialChallengePostRequirements()
  );

  const [updateChallenge] = useMutation(UPDATE_CHALLENGE);

  // const audienceBrandClicked = (brandOption: OptionInterface) => {
  //   setBrandAudience((prevData) => {
  //     const newOptions = new Set(prevData);
  //     newOptions.add(brandOption);
  //     return Array.from(newOptions);
  //   });
  // };

  // const audienceBrandCategoryClicked = (brandOption: OptionInterface) => {
  //   setBrandCategoriesAudience((prevData) => {
  //     const newOptions = new Set(prevData);
  //     newOptions.add(brandOption);
  //     return Array.from(newOptions);
  //   });
  // };

  useEffect(() => {
    getAllBrandCategories().then((brandCategories) => {
      setBrandCategories(
        brandCategories.map((category) => {
          return {
            id: category.id,
            name: category.name,
            imageUrl: null,
          };
        })
      );
    });
    getAllBrands(SIMPLE_BRANDS).then((brands) => {
      setBrands(brands);
      // setBrandOptions(
      //   brands.map((brand) => {
      //     return {
      //       id: brand.id,
      //       name: brand.name,
      //       imageUrl: brand.logoUrl,
      //     };
      //   })
      // );
    });
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // if (name === "audienceType") {
      //   if (value.audienceType === "TARGETED") {
      //     setShowAudience(true);
      //   } else {
      //     setShowAudience(false);
      //   }
      // }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    setBrandAudience(initAudienceValues(challenge));
    setBrandCategoriesAudience(
      challenge.audience.brandCategories.map((category) => {
        return {
          id: category.id,
          name: category.name,
          imageUrl: null,
        };
      })
    );
    setCreatorsAudience(
      new Set(challenge.audience.creators.map((creator) => creator.id))
    );
    // setShowAudience(challenge.audienceType === "TARGETED");
  }, [challenge]);

  const updateState = async (state: string, action: string) => {
    try {
      const result = await updateChallenge({
        variables: {
          challengeId: challenge.id,
          state: state,
        },
      });
      console.log(result);
      if (result.data.updateChallenge) {
        onSubmit(result.data.updateChallenge as Challenge);
      } else {
        setError("Failed to" + action + "the challenge. Try again.");
      }
    } catch (err) {
      console.log(err);
      setError("Failed to" + action + "the challenge. Try again.");
    }
  };

  const submit: SubmitHandler<EditChallengeFormInput> = async (
    data: EditChallengeFormInput
  ) => {
    console.log(data);
    setIsLoading(true);

    const isBrandSuggestedChallenge =
      challenge.state === "PENDING_REVIEW" &&
      challenge.source === "BRAND_ADMIN";

    if (isBrandSuggestedChallenge && (!challengeCategory || !data.title) && !challenge.challengeTemplate) {
      setError("Template and title are required.");
      setIsLoading(false);
      return;
    }

    try {
      const exampleUrl = getValues("exampleUrl");
      const result = await updateChallenge({
        variables: {
          description: data.description,
          challengeId: challenge.id,
          audienceType: data.audienceType,
          brandIds: brandAudience.map((option) => option.id),
          brandCategoryIds: brandCategoriesAudience.map((option) => option.id),
          creatorIds: Array.from(creatorsAudience),
          exampleUrl: exampleUrl,
          difficulty: difficulty,
          tagRequirements: Array.from(tags).join(" "),
          startDate: getRoundedDate(startDate)?.toString(), // round down to nearest second
          endDate: getRoundedDate(endDate)?.toString(), // round down to nearest second
          state: isBrandSuggestedChallenge ? "INACTIVE" : null,
          isCommercialSoundsOnly:
            challenge.state === "PENDING_REVIEW"
              ? isCommercialSoundsOnly
              : null,
          brandProductId: selectedBrandProduct?.id,
          challengePostRequirements: challengePostRequirements?.map(
            (postRequirement) => postRequirement.id
          ),
          categoryId: challengeCategory?.id,
          title: data.title,
        },
      });
      console.log(result);
      if (result.data.updateChallenge) {
        onSubmit(result.data.updateChallenge as Challenge);
      } else {
        setError("Failed to update the challenge. Try again.");
      }
    } catch (err) {
      console.log(err);
      setError("Failed to update the challenge. Try again.");
    }
    setIsLoading(false);
  };

  return (
    <form className="w-full space-y-4">
      {/* Brand */}
      <div className="flex items-center justify-between pt-2">
        <div className="flex items-center space-x-2">
          <img
            className="h-8 w-8 rounded-full"
            src={challenge.brand?.logoUrl}
            alt=""
          />
          <p className="text-md font-semibold">
            {challenge.brand?.name} Challenge {`(ID: ${challenge.id})`}
          </p>
        </div>
        <div>
          {challenge.state === "PENDING_REVIEW" &&
            challenge.source === "BRAND_ADMIN" &&
            challenge.brand?.isChallengeReviewRequired && (
              <p className="text-sm font-medium">
                🔔 Challenge Review Required
              </p>
            )}
        </div>
      </div>

      <ChallengeFormDataComponent
        brands={brands}
        selectedBrandId={challenge.brand?.id}
        challengeCategory={challengeCategory}
        setChallengeCategory={setChallengeCategory}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        difficulty={difficulty}
        setDifficulty={setDifficulty}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        tags={tags}
        setTags={setTags}
        isCommercialSoundsOnly={isCommercialSoundsOnly}
        setIsCommercialSoundsOnly={setIsCommercialSoundsOnly}
        selectedBrandProduct={selectedBrandProduct}
        setSelectedBrandProduct={setSelectedBrandProduct}
        challengePostRequirements={challengePostRequirements}
        setChallengePostRequirements={setChallengePostRequirements}
        setError={setError}
        isEditForm={true}
        shouldShowCommercialSoundsInput={challenge.state === "PENDING_REVIEW"}
      />

      {/* Audience */}
      {/*
       <div className="mt-2 rounded-md border px-2 py-4">
        <h2 className="text-lg font-bold">Audience</h2>
        <p className="text-sm text-gray-400">
          Select the targeting for the challenge.
        </p>
        <label
          htmlFor="audienceType"
          className="block text-sm font-medium text-gray-700"
        >
          Audience Type
        </label>
        <select
          id="audienceType"
          name="audienceType"
          {...register("audienceType")}
          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue="ALL"
        >
          <option value={"ALL"}>Everyone</option>
          <option value={"TARGETED"}>Targeted</option>
        </select>
        {showAudience && (
          <>
            <div className="mt-2 flex flex-col">
              <Dropdown
                options={brandOptions}
                activeOptionId={null}
                optionClicked={audienceBrandClicked}
                label={
                  <>
                    <p className="text-base">Brand:</p>
                    <p className="text-xs text-gray-400">
                      This challenge will be visible to creators that belong
                      to any of the brands chosen below.
                    </p>
                  </>
                }
                placeholderImage={
                  <BuildingStorefrontIcon className="h-6 w-6 flex-shrink-0 rounded-full" />
                }
              />
              <div className="mt-2 flex flex-wrap">
                {Array.from(brandAudience).map((brandOption) => {
                  return (
                    <div
                      key={brandOption.id}
                      className="mb-2 mr-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-2 text-sm font-medium text-kale-green-500"
                    >
                      <div className="mr-1 flex flex-col">
                        <p className="font-bold">{brandOption.name}</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          setBrandAudience((prevData) => {
                            const newOptions = new Set(prevData);
                            newOptions.delete(brandOption);
                            return Array.from(newOptions);
                          });
                        }}
                        className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
                      >
                        <span className="sr-only">
                          Remove {brandOption.name}
                        </span>
                        <XMarkIcon className="p-1" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-2 flex flex-col">
              <Dropdown
                options={brandCategories}
                activeOptionId={null}
                optionClicked={audienceBrandCategoryClicked}
                label={
                  <>
                    <p className="text-base">Brand Categories:</p>
                    <p className="text-xs text-gray-400">
                      This challenge will be visible to creators that belong
                      to any of the brands chosen below.
                    </p>
                  </>
                }
              />
              <div className="mt-2 flex flex-wrap">
                {Array.from(brandCategoriesAudience).map((brandOption) => {
                  return (
                    <div
                      key={brandOption.id}
                      className="mb-2 mr-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-2 text-sm font-medium text-kale-green-500"
                    >
                      <div className="mr-1 flex flex-col">
                        <p className="font-bold">{brandOption.name}</p>
                      </div>
                      <button
                        type="button"
                        onClick={() => {
                          setBrandCategoriesAudience((prevData) => {
                            const newOptions = new Set(prevData);
                            newOptions.delete(brandOption);
                            return Array.from(newOptions);
                          });
                        }}
                        className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
                      >
                        <span className="sr-only">
                          Remove {brandOption.name}
                        </span>
                        <XMarkIcon className="p-1" />
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-2 flex flex-col">
              <p className="text-base">Creator Ids:</p>
              <label
                htmlFor="newCreatorId"
                className="block text-xs text-gray-400"
              >
                Add a creator ids that will be targeted to this challenge.
              </label>
              <div className="flex w-full">
                <div className="mt-1 w-full">
                  <input
                    {...register("newCreatorId")}
                    type="text"
                    name="newCreatorId"
                    id="newCreatorId"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="CREATOR_ID"
                  />
                </div>
                <button
                  type="button"
                  onClick={() => {
                    const newCreatorId = watch("newCreatorId");
                    const newCreatorIds = new Set(creatorsAudience);
                    newCreatorIds.add(newCreatorId);
                    setCreatorsAudience(newCreatorIds);
                    setValue("newCreatorId", null);
                  }}
                  className="ml-3 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-60"
                >
                  Add
                </button>
              </div>
            </div>
            <div className="mt-4 flex flex-wrap space-x-2">
              {Array.from(creatorsAudience).map((creatorId) => {
                return (
                  <div
                    key={creatorId}
                    className="mb-2 inline-flex items-center rounded-full bg-kale-mint-500 py-1 pl-4 pr-2 text-sm font-medium text-kale-green-500"
                  >
                    <div className="mr-1 flex flex-col">
                      <p className="font-bold">{creatorId}</p>
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        console.log(creatorsAudience);
                        const newCreatorIds = new Set(creatorsAudience);
                        newCreatorIds.delete(creatorId);
                        setCreatorsAudience(newCreatorIds);
                      }}
                      className="ml-0.5 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-kale-green-400 hover:bg-kale-green-200 hover:text-kale-green-500 focus:bg-kale-green-500 focus:text-white focus:outline-none"
                    >
                      <span className="sr-only">Remove {creatorId}</span>
                      <XMarkIcon className="p-1" />
                    </button>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div> 
    */}
      <div className="flex justify-between space-x-2 pt-5">
        <Button
          type="button"
          className="z-0 hover:cursor-pointer"
          onClick={() => {
            if (
              challenge.state === "PENDING_REVIEW" &&
              challenge.source === "BRAND_ADMIN"
            ) {
              updateState("REJECTED", "reject");
            } else {
              updateState("DELETED", "delete");
            }
          }}
          disabled={isLoading}
          color="red"
        >
          {challenge.state === "PENDING_REVIEW" &&
          challenge.source === "BRAND_ADMIN"
            ? "Reject"
            : "Delete"}
        </Button>

        {isLoading && (
          <div className="flex flex-wrap content-center justify-center">
            <Spinner size="h-6 w-6" />
          </div>
        )}

        <Button
          type="button"
          onClick={handleSubmit(submit)}
          disabled={isLoading}
          color="emerald"
          className="hover:cursor-pointer"
        >
          {challenge.state === "PENDING_REVIEW" &&
          challenge.source === "BRAND_ADMIN"
            ? "Approve"
            : "Update"}
        </Button>
      </div>

      <div className="mt-2 flex justify-end">
        <p className="font-bold text-red-500">{error}</p>
      </div>
    </form>
  );
}
function initAudienceValues(challenge: Challenge): OptionInterface[] {
  if (challenge.audienceType === "TARGETED") {
    return challenge.audience.brands.map((brand) => {
      return {
        id: brand.id,
        name: brand.name,
        imageUrl: brand.logoUrl,
      };
    });
  } else {
    return [
      {
        id: challenge.brand.id,
        name: challenge.brand.name,
        imageUrl: challenge.brand.logoUrl,
      },
    ];
  }
}
