import ChallengeThemes from "../components/templates/ChallengeThemes";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import ChallengeTrends from "../components/templates/ChallengeTrends";

export default function ChallengeTemplatesPage() {

  const tabs = [
    { name: "Themes", href: `/templates/themes`, current: true },
    { name: "Trends", href: `/templates/trends`, current: false }
  ];

  return (
    <div className="mt-4 px-4 sm:px-6 lg:px-8 space-y-6 mb-4">
      {/* Header */}
      <div className="space-y-2">
        <h1 className="text-xl font-semibold text-gray-900">Challenge Templates</h1>
        <p className="text-sm text-gray-700">A list of all the challenge templates</p>
      </div>

      <div className="border-b border-gray-200">
        <div className="block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={(isActive) =>
                  (isActive
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600") +
                  "whitespace-nowrap pb-4 text-sm font-medium"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div>
        <Switch>
          <Route path="/templates/themes">
            <ChallengeThemes />
          </Route>
          <Route exact path="/templates/trends">
            <ChallengeTrends />
          </Route>
          <Route path="/templates">
            <Redirect to="/templates/themes" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}