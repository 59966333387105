import { FaceSmileIcon, MusicalNoteIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Strong, Text } from "../catalyst/text";
import { Heading } from "../catalyst/heading";
import { Description, Field, Fieldset, Label } from "../catalyst/fieldset";
import { useEmojiPickerForInput } from "../../utils/emojiPicker";
import { useEffect, useRef, useState } from "react";
import { Input } from "../catalyst/input";
import { Button } from "../catalyst/button";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CREATE_CHALLENGE_TEMPLATE } from "../../graphql/mutations";
import { BrandCategory, Spinner } from "@kalecard/common";
import { getAllBrandCategories } from "../../utils/brands";
import { CHALLENGE_TEMPLATES } from "../../graphql/queries";
import BrandCategoryDropdown from "../brands/BrandCategoryDropdown";
import { Badge } from "../catalyst/badge";

interface NewChallengeTemplateInput {
  title: string,
  soundUrl?: string,
  exampleUrl?: string,
  template?: string
}

export default function NewChallengeTemplateForm({ templateType, onSubmit } : { templateType: string, onSubmit: () => void} ) {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit, setValue, getValues } = useForm<NewChallengeTemplateInput>();
  const [createChallengeTemplate] = useMutation(CREATE_CHALLENGE_TEMPLATE);
  const [error, setError] = useState(null);

  const [brandCategories, setBrandCategories] = useState<BrandCategory[]>([]);
  const [selectedBrandCategories, setSelectedBrandCategories] = useState<BrandCategory[]>([]);

  useEffect(() => {
    getAllBrandCategories().then((brandCategories) => {
      setBrandCategories(brandCategories);
    });
  }, []);

  const removeBrandCategory = (category) => {
    const updatedBrandCategories = selectedBrandCategories.filter((brandCategory) => brandCategory.id !== category.id);
    setSelectedBrandCategories(updatedBrandCategories);
  }

  const submit: SubmitHandler<NewChallengeTemplateInput> = async (
    data: NewChallengeTemplateInput
  ) => {

    if (templateType === "TREND" && selectedBrandCategories.length === 0) {
      setError("At least one brand category is required.");
      return;
    }

    setIsLoading(true);
    try {
      const result = await createChallengeTemplate({
        variables: {
          title: data.title,
          soundUrl: data.soundUrl,
          exampleUrl: data.exampleUrl,
          template: data.template,
          shouldCreateChallengeRequests: templateType === "TREND",
          templateType: templateType,
          brandCategoryIds: selectedBrandCategories.map((brandCategory) => brandCategory.id)
        },
        refetchQueries: [CHALLENGE_TEMPLATES],
      });
      console.log(result);
      setError(null);
      onSubmit();
    } catch (err) {
      console.log(err);
      setError(err);
    }
    setIsLoading(false);
  };

  return (
    <form className="space-y-2">
      {/* Header */}
      <div className="flex flex-row space-x-2 justify-center items-center capitalize">
        <Heading>New {templateType.toLowerCase()}</Heading>
        <MusicalNoteIcon className="h-5 w-5"/>
      </div>
      <Fieldset className="space-y-4">
        {/* Title */}
        <ChallengeTemplateTitle register={register} setValue={setValue} getValues={getValues} />

        {/* Example Video */}
        <Field>
          <Label>Example Video</Label>
          <Input
            type="text"
            {...register("exampleUrl", { required: templateType === "TREND" })}
          >
          </Input>
        </Field>

        {/* Example Video */}
        <Field>
          <Label>Sound Link</Label>
          <Input
            type="text"
            {...register("soundUrl")}
          >
          </Input>
        </Field>

        <div className="space-y-1">
          <div className="flex space-x-2 items-center">
            <Text><Strong>Select relevant brand categories:</Strong></Text>
            <BrandCategoryDropdown 
              brandCategories={brandCategories} 
              selectedBrandCategories={selectedBrandCategories} 
              setSelectedBrandCategories={setSelectedBrandCategories}
            />
          </div>
          <Description>A challenge request for this trend will be sent to every brand mapped to the selected categories.</Description>
          <div className="space-x-2 text-wrap space-y-1">
            {selectedBrandCategories.map((brandCategory) => (
              <Badge color="indigo" key={brandCategory.id}>
                {brandCategory.name}
                <XMarkIcon className="h-4 w-4 hover:cursor-pointer" onClick={() => removeBrandCategory(brandCategory)} />
              </Badge>
            ))}
          </div>
        </div>
      </Fieldset>

      <div className="flex justify-end items-center space-x-4">
        {isLoading && (<Spinner size="h-5 w-5" />)}
        <Button color="emerald" onClick={handleSubmit(submit)}>
          Submit
        </Button>
      </div>
      {error && (
        <p className="text-sm text-red-500 text-right">{error}</p>
      )}
    </form>
  );
}

function ChallengeTemplateTitle({
  register,
  setValue,
  getValues,
}) {
  const [isEmojiOpen, setIsEmojiOpen] = useState<boolean>(false);
  const pickerRef = useRef(null);
  const inputRef = useRef(null);
  const inputName = "title";
  const querySelector = 'input[type="text"]';

  const { addEmojiAtCursor, handleCursorChange } = useEmojiPickerForInput({
    setValue,
    getValues,
    inputName,
    querySelector,
    pickerRef,
    inputRef,
    setIsEmojiOpen,
  });

  return (
    <Field className="space-y-2">
      <Label>Trend Title</Label>
      <div className="flex space-x-2" ref={inputRef}>
        <Input
          maxLength={26}
          type="text"
          {...register(inputName, { required: true })}
          onClick={handleCursorChange}
          onChange={handleCursorChange}
        />
        <Button
          type="button"
          plain
          className="hover:cursor-pointer"
          onClick={() => setIsEmojiOpen(!isEmojiOpen)}
        >
          <FaceSmileIcon />
        </Button>
      </div>
      {isEmojiOpen && (
        <div className="relative flex">
          <div className="absolute left-0 top-0 z-10" ref={pickerRef}>
            <Picker
              data={data}
              onEmojiSelect={(emoji) => {
                addEmojiAtCursor(emoji);
              }}
              autoFocus={true}
            />
          </div>
        </div>
      )}
    </Field>
  );
}