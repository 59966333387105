import { useMutation, useQuery } from "@apollo/client";
import { CREATE_BRAND, UPDATE_BRAND_DETAILS } from "../../../graphql/mutations";
import {
  ADMINS,
  AdminsInterface,
  BRAND_CATEGORIES,
} from "../../../graphql/queries";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../../catalyst/fieldset";
import { Input } from "../../catalyst/input";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../../catalyst/dropdown";
import {
  ChevronDownIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Badge } from "../../catalyst/badge";
import { Text, Strong } from "../../catalyst/text";
import {
  Brand,
  BrandCategory,
  Connection,
  KaleAdmin,
  Spinner,
} from "@kalecard/common";
import { Button } from "../../catalyst/button";
import { Tooltip } from "flowbite-react";

interface BrandDetailsInput {
  socialHandle: string;
  tiktokSocialHandle: string;
  referralCode: string;
}

interface BrandCategoriesData {
  brandCategories: Connection<BrandCategory>;
}

interface CreateBrandData {
  createBrand: Brand;
}

export default function BrandDetails({
  brand,
  isCreationFlow,
  newBrand,
}: {
  brand?: Brand;
  isCreationFlow: boolean;
  newBrand?: (brand: Brand) => void;
}) {
  const [loading, setLoading] = useState(false);
  const [brandCategories, setBrandCategories] = useState<BrandCategory[]>(
    brand?.brandCategories ?? []
  );

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const [salesRep, setSalesRep] = useState<KaleAdmin>(brand?.salesRep);
  const [accountManager, setAccountManager] = useState<KaleAdmin>(
    brand?.accountManager
  );

  const [updateBrandDetails] = useMutation(UPDATE_BRAND_DETAILS);

  const [createBrand] = useMutation<CreateBrandData>(CREATE_BRAND);

  const { data: brandCategoriesData, loading: brandCategoriesLoading } =
    useQuery<BrandCategoriesData>(BRAND_CATEGORIES);

  const addBrandCategory = (brandCategory: BrandCategory) => {
    console.log(brandCategory);
    const existingCategory = brandCategories.find(
      (category) => category.id === brandCategory.id
    );

    if (existingCategory == null) {
      setBrandCategories([...brandCategories, brandCategory]);
    }
  };

  const removeBrandCategory = (brandCategory: BrandCategory) => {
    const updatedCategories = brandCategories.filter(
      (category) => category.id !== brandCategory.id
    );
    setBrandCategories(updatedCategories);
  };

  const { register, handleSubmit } = useForm<BrandDetailsInput>({
    defaultValues: {
      ...brand,
    },
  });

  const { loading: adminsLoading, data: adminsData } =
    useQuery<AdminsInterface>(ADMINS, {
      variables: {
        roles: ["ADMIN", "OWNER"],
      },
    });

  const onSubmit = async (data: BrandDetailsInput) => {
    if (data.referralCode.includes(" ") || data.referralCode.includes("_")) {
      setErrorMessage(
        "Please remove all spaces and underscores from the referral code."
      );
      clearErrorMessage();
      return;
    }

    if (brandCategories.length === 0) {
      setErrorMessage("Brand must be mapped to at least one brand category.");
      clearErrorMessage();
      return;
    }

    setLoading(true);
    try {
      if (!isCreationFlow) {
        const variables = {
          brandId: brand.id,
          socialHandle: data.socialHandle,
          tiktokSocialHandle: data.tiktokSocialHandle,
          referralCode: data.referralCode,
          brandCategoryIds: brandCategories.map((category) => category.id),
          salesRepAdminId: salesRep?.id,
          accountManagerAdminId: accountManager?.id,
        };

        await updateBrandDetails({
          variables: variables,
        });
        setSuccessMessage("Congrats! Your changes have been applied.");
        clearSuccessMessage();
      } else {
        const variables = {
          socialHandle: data.socialHandle,
          tiktokSocialHandle: data.tiktokSocialHandle,
          referralCode: data.referralCode,
          brandCategoryIds: brandCategories.map((category) => category.id),
          salesRepAdminId: salesRep?.id,
          accountManagerAdminId: accountManager?.id,
        };

        const result = await createBrand({
          variables: variables,
        });
        newBrand(result.data.createBrand);
      }
      setErrorMessage(null);
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <>
      <form className="space-y-4">
        <Fieldset>
          <div className="flex justify-center">
            <Legend>Brand Details</Legend>
          </div>
          <FieldGroup>
            <div className="flex justify-between">
              <Field className="space-y-3">
                <div className="flex items-center space-x-1">
                  <Label>
                    <Strong>Name</Strong>
                  </Label>
                  <Tooltip
                    content={"This field can only be updated through HubSpot."}
                  >
                    <InformationCircleIcon className="h-5 w-5" />
                  </Tooltip>
                </div>
                <Input name="name" disabled={true} value={brand.name} />
              </Field>
              <Field>
                <Label>Referral Code</Label>
                <Input
                  name="referralCode"
                  {...register("referralCode", { required: true })}
                />
              </Field>
              <Field>
                <Label>Instagram Handle</Label>
                <div className="mt-3 flex items-center rounded-lg border border-gray-200 bg-gray-100">
                  <p className="px-2 text-sm text-gray-500">instagram.com/</p>
                  <Input
                    name="socialHandle"
                    {...register("socialHandle", { required: true })}
                  />
                </div>
              </Field>
              <Field>
                <Label>TikTok Handle</Label>
                <div className="mt-3 flex items-center rounded-lg border border-gray-200 bg-gray-100">
                  <p className="px-2 text-sm text-gray-500">tiktok.com/@</p>
                  <Input
                    name="tiktokSocialHandle"
                    {...register("tiktokSocialHandle", { required: true })}
                  />
                </div>
              </Field>
            </div>
            <div className="flex justify-between">
              <Field className="space-y-4">
                <Label>Brand Categories</Label>
                <Description>
                  The brand will be mapped to the categories shown below. Select
                  up to 3 options.
                </Description>
                {brandCategoriesLoading ? (
                  <div className="flex flex-wrap content-center justify-center pr-2">
                    <Spinner size="h-4 w-4" />
                  </div>
                ) : (
                  <Dropdown>
                    <DropdownButton outline>
                      Select a category
                      <ChevronDownIcon />
                    </DropdownButton>
                    <DropdownMenu>
                      {brandCategoriesData?.brandCategories?.edges.map(
                        (edge) => (
                          <DropdownItem
                            key={edge.node.id}
                            onClick={() => addBrandCategory(edge.node)}
                          >
                            {edge.node.name}
                          </DropdownItem>
                        )
                      )}
                    </DropdownMenu>
                  </Dropdown>
                )}
                <div className="flex flex-wrap gap-2">
                  {brandCategories.map((brandCategory) => (
                    <Badge
                      key={brandCategory.id}
                      color="lime"
                      className="space-x-1"
                    >
                      <Text>
                        <Strong>{brandCategory.name}</Strong>
                      </Text>
                      <XMarkIcon
                        className="h-4 w-4 text-gray-500"
                        onClick={() => removeBrandCategory(brandCategory)}
                      />
                    </Badge>
                  ))}
                </div>
              </Field>

              {/* Sales Rep */}
              <Field className="space-y-3">
                <Label>Sales Rep (Optional)</Label>
                <div>
                  {loading || adminsLoading ? (
                    <div className="flex flex-wrap content-center">
                      <Spinner size="h-6 w-6" />
                    </div>
                  ) : (
                    <AdminsDropdown
                      admins={adminsData.admins}
                      selectedAdmin={salesRep}
                      onAdminSelect={setSalesRep}
                    />
                  )}
                </div>
              </Field>

              {/* Account Manager */}
              <Field className="space-y-3">
                <Label>Account Manager (Optional)</Label>
                <div>
                  {loading || adminsLoading ? (
                    <div className="flex flex-wrap content-center">
                      <Spinner size="h-6 w-6" />
                    </div>
                  ) : (
                    <AdminsDropdown
                      admins={adminsData.admins}
                      selectedAdmin={accountManager}
                      onAdminSelect={setAccountManager}
                    />
                  )}
                </div>
              </Field>
            </div>
          </FieldGroup>
        </Fieldset>
        <div className="flex flex-row items-center justify-end space-x-4">
          {successMessage && (
            <p className="text-sm font-medium text-green-500">
              {successMessage}
            </p>
          )}
          {errorMessage && (
            <p className="text-sm font-medium text-red-500">{errorMessage}</p>
          )}
          {loading && (
            <div className="flex flex-wrap content-center justify-center pr-2">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <Button color="indigo" onClick={handleSubmit(onSubmit)}>
            {isCreationFlow ? "Create Brand" : "Save Changes"}
          </Button>
        </div>
      </form>
    </>
  );
}

function AdminsDropdown({
  admins,
  selectedAdmin,
  onAdminSelect,
}: {
  admins: KaleAdmin[];
  selectedAdmin?: KaleAdmin;
  onAdminSelect: (admin: KaleAdmin) => void;
}) {
  return (
    <Dropdown>
      <DropdownButton outline className="min-w-44 justify-between">
        <p className="text-sm font-normal">
          {selectedAdmin ? selectedAdmin.name : "Select an admin"}
        </p>
        <ChevronDownIcon />
      </DropdownButton>
      <DropdownMenu>
        {admins.map((admin) => (
          <DropdownItem onClick={() => onAdminSelect(admin)}>
            {admin.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}

export function isBrandDetailsStepComplete(brand: Brand) {
  return (
    brand.name &&
    brand.socialHandle &&
    brand.tiktokSocialHandle &&
    brand.referralCode &&
    brand.brandCategories.length > 0
  );
}
