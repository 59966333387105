import { ChallengeTemplate, timeDifferenceForDate } from "@kalecard/common";
import { TableCell, TableRow } from "../catalyst/table";
import { Strong, Text } from "../catalyst/text";
import { Badge } from "../catalyst/badge";
import { MusicalNoteIcon, PowerIcon, VideoCameraIcon } from "@heroicons/react/24/outline";
import { Button } from "../catalyst/button";
import { ToolTip } from "../ToolTip";
import { useState } from "react";
import { Dialog } from "../catalyst/dialog";
import DeactivateChallengeTemplateForm from "../forms/DeactivateChallengeTemplateForm";

export default function ChallengeTemplateRow({ challengeTemplate } : { challengeTemplate: ChallengeTemplate }) {
  return (
    <TableRow key={challengeTemplate.id}>
      <TableCell>
        <Strong>{challengeTemplate.title}</Strong>
      </TableCell>
      <TableCell>
        <ChallengeTemplateStatus challengeTemplate={challengeTemplate} />
      </TableCell>
      <TableCell>
        <ChallengeElapsedTime createdAt={challengeTemplate.createdAt} />
      </TableCell>
      <TableCell>
        <ChallengeLinks exampleUrl={challengeTemplate.exampleUrl} soundUrl={challengeTemplate.soundUrl} />
      </TableCell>
      <TableCell>
        <div className="flex text-center">
          <Badge color="blue">
            {challengeTemplate.activeChallengesCount}
          </Badge>
        </div>
      </TableCell>
    </TableRow>
  )
}

function ChallengeTemplateStatus({ challengeTemplate } : { challengeTemplate: ChallengeTemplate }) {
  const [isDeactivateTemplateOpen, setIsDeactivateTemplateOpen] = useState(false);
  return (
    <div className="flex space-x-2 items-center">
      <Badge className="capitalize" color={challengeTemplate.status === "ACTIVE" ? "emerald" : "red"}>
        {challengeTemplate.status.toLowerCase()}
      </Badge>
      {challengeTemplate.status === "ACTIVE" && (
        <Button outline onClick={() => setIsDeactivateTemplateOpen(true)}>
          <PowerIcon />
        </Button>
      )}
      <Dialog open={isDeactivateTemplateOpen} onClose={setIsDeactivateTemplateOpen}>
        <DeactivateChallengeTemplateForm challengeTemplate={challengeTemplate} onSubmit={() => setIsDeactivateTemplateOpen(false)}/>
      </Dialog>
    </div>
  )
}

function ChallengeElapsedTime({ createdAt } : { createdAt: string }) {
  return <Text>{timeDifferenceForDate(createdAt)}</Text>
}

function ChallengeLinks({ exampleUrl, soundUrl} : { exampleUrl?: string, soundUrl?: string }) {
  return (
    <div className="flex space-x-2">
      {exampleUrl && (
        <ToolTip message="Example video">
          <Button outline href={exampleUrl} target="_blank">
            <VideoCameraIcon />
          </Button>
        </ToolTip>
      )}
      {soundUrl && (
        <ToolTip message="Sound link">
          <Button outline href={soundUrl} target="_blank">
            <MusicalNoteIcon />
          </Button>
        </ToolTip>
      )}
    </div>
  )
}