import { Brand, numberWithCommas, Spinner } from "@kalecard/common";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { UPDATE_BRAND_INVOICING_INFO } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { Button } from "../../catalyst/button";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
  Legend,
} from "../../catalyst/fieldset";
import { Radio, RadioField, RadioGroup } from "../../catalyst/radio";
import { Strong, Text } from "../../catalyst/text";
import { Input } from "../../catalyst/input";
import { BrandSettingsField } from "./BrandSettingsField";
import { Tooltip } from "flowbite-react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

interface BrandInvoicingInput {
  invoiceEmailAddress: string;
  paymentPeriodDays: number;
  autoRechargeEnabled: boolean;
  recurringChargesEnabled: boolean;
  autoChargeDefaultPaymentMethod: boolean;
  enableWireTransferInvoicePayment: boolean;
}

export default function BrandInvoicingInfo({ brand }: { brand: Brand }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const { register, handleSubmit, getValues, setValue } =
    useForm<BrandInvoicingInput>({
      defaultValues: {
        ...brand,
      },
    });

  const [updateBrandInvoicing] = useMutation(UPDATE_BRAND_INVOICING_INFO);

  const onSubmit = async (data: BrandInvoicingInput) => {
    if (
      data.autoRechargeEnabled == null ||
      data.recurringChargesEnabled == null ||
      data.autoChargeDefaultPaymentMethod == null ||
      data.enableWireTransferInvoicePayment == null
    ) {
      setErrorMessage("Please select an option for every setting.");
      clearErrorMessage();
      return;
    }

    setLoading(true);
    try {
      const variables = {
        brandId: brand.id,
        invoiceEmailAddress: data.invoiceEmailAddress,
        paymentPeriodDays: data.paymentPeriodDays
          ? Number(data.paymentPeriodDays)
          : null,
        autoRechargeEnabled: data.autoRechargeEnabled,
        recurringChargesEnabled: data.recurringChargesEnabled,
        autoChargeDefaultPaymentMethod: data.autoChargeDefaultPaymentMethod,
        enableWireTransferInvoicePayment: data.enableWireTransferInvoicePayment,
      };

      await updateBrandInvoicing({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage("Congrats! Your changes have been applied.");
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to apply your changes, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form className="space-y-4" key="payments">
      <Fieldset>
        <div className="flex justify-center">
          <Legend>Invoicing Info</Legend>
        </div>
        <FieldGroup>
          <div className="flex flex-row space-x-10">
            <Field className="w-full space-y-3">
              <div className="flex items-center space-x-1">
                <Label>
                  <Strong>Platform Fee</Strong>
                </Label>
                <Tooltip
                  content={"This field can only be updated through HubSpot."}
                >
                  <InformationCircleIcon className="h-5 w-5" />
                </Tooltip>
              </div>
              <div className="flex items-center space-x-1">
                <Text>$</Text>
                <Input
                  disabled={true}
                  step={0.01}
                  name="platformFee"
                  id="platformFee"
                  value={
                    brand.platformFee
                      ? numberWithCommas(brand.platformFee)
                      : null
                  }
                  placeholder="0"
                />
                <Text>USD</Text>
              </div>
            </Field>
            <Field className="w-full space-y-3">
              <div className="flex items-center space-x-1">
                <Label>
                  <Strong>Content Budget</Strong>
                </Label>
                <Tooltip
                  content={"This field can only be updated through HubSpot."}
                >
                  <InformationCircleIcon className="h-5 w-5" />
                </Tooltip>
              </div>
              <div className="flex items-center space-x-1">
                <Text>$</Text>
                <Input
                  disabled={true}
                  step={0.01}
                  name="contentBudgetMax"
                  id="contentBudgetMax"
                  placeholder="0"
                  value={
                    brand.contentBudgetMax
                      ? numberWithCommas(brand.contentBudgetMax)
                      : null
                  }
                />
                <Text>USD</Text>
              </div>
            </Field>
          </div>
          <div className="flex space-x-10">
            <Field className="w-full">
              <Label>Invoicing Email</Label>
              <Description>
                This is the email the brand will receive invoices at.
              </Description>
              <Input
                placeholder="invoice@example.com"
                {...register("invoiceEmailAddress", {
                  required: true,
                })}
                type="email"
              />
            </Field>
            <div className="w-full"></div>
          </div>

          <Legend className="text-center">Invoicing Settings</Legend>
          <div className="grid grid-cols-2 gap-x-10 gap-y-4">
            <BrandSettingsField
              key={
                "recurringChargesEnabled" + getValues("recurringChargesEnabled")
              }
              label="Platform fee recurring charges"
              description="Whether we should charge the brand a recurring platform fee every month."
              name="recurringChargesEnabled"
              value={getValues("recurringChargesEnabled")}
              setValue={setValue}
            />
            <BrandSettingsField
              key={"autoRechargeEnabled" + getValues("autoRechargeEnabled")}
              label="Monthly content budget auto-recharge"
              description="Whether we should recharge the content budget for the brand when it reaches $0 once a month."
              name="autoRechargeEnabled"
              value={getValues("autoRechargeEnabled")}
              setValue={setValue}
            />
            <BrandSettingsField
              key={
                "autoChargeDefaultPaymentMethod" +
                getValues("autoChargeDefaultPaymentMethod")
              }
              label="Auto charge default payment method"
              description="Whether we should charge the brand's default payment method or 'off' for invoice always."
              name="autoChargeDefaultPaymentMethod"
              value={getValues("autoChargeDefaultPaymentMethod")}
              setValue={setValue}
            />
            <BrandSettingsField
              key={
                "enableWireTransferInvoicePayment" +
                getValues("enableWireTransferInvoicePayment")
              }
              label="Wire transfer invoice payment option"
              description="Whether we expose wire transfer to the brand as a payment option for invoices."
              name="enableWireTransferInvoicePayment"
              value={getValues("enableWireTransferInvoicePayment")}
              setValue={setValue}
            />
            <PaymentPeriodField
              name="paymentPeriodDays"
              value={getValues("paymentPeriodDays").toString()}
              setValue={setValue}
            />
          </div>
        </FieldGroup>
      </Fieldset>
      <div className="flex flex-row items-center justify-end space-x-4">
        {successMessage && (
          <p className="text-sm font-medium text-green-500">{successMessage}</p>
        )}
        {errorMessage && (
          <p className="text-sm font-medium text-red-500">{errorMessage}</p>
        )}
        {loading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <Button color="indigo" onClick={handleSubmit(onSubmit)}>
          Save Changes
        </Button>
      </div>
    </form>
  );
}

export function isBrandInvoicingStepComplete(brand: Brand) {
  return (
    brand.autoRechargeEnabled != null &&
    brand.recurringChargesEnabled != null &&
    brand.autoChargeDefaultPaymentMethod != null &&
    brand.enableWireTransferInvoicePayment != null &&
    brand.invoiceEmailAddress &&
    brand.platformFee != null &&
    brand.contentBudgetMax != null &&
    brand.paymentPeriodDays
  );
}

function PaymentPeriodField({ name, value, setValue }) {
  const handleChange = (val: string) => {
    setValue(name, val);
  };
  return (
    <Field className="flex items-center justify-between">
      <div>
        <Label>Payment Period</Label>
        <Description className="pr-8">
          The number of days a brand has to pay the invoice from the date it is
          issued
        </Description>
      </div>
      <div>
        <RadioGroup onChange={handleChange} defaultValue={value}>
          <div className="flex flex-col">
            <RadioField>
              <Label>Net30</Label>
              <Radio value="30" color="emerald" />
            </RadioField>
            <RadioField>
              <Label>Net60</Label>
              <Radio value="60" color="emerald" />
            </RadioField>
          </div>
        </RadioGroup>
      </div>
    </Field>
  );
}
