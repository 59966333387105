import {Heading} from "../catalyst/heading";
import {Field, FieldGroup, Fieldset, Label, Legend} from "../catalyst/fieldset";
import {Input} from "../catalyst/input";
import {Textarea} from "../catalyst/textarea";
import {Divider} from "../catalyst/divider";
import {Radio, RadioField, RadioGroup} from "../catalyst/radio";
import {Text} from "../catalyst/text";
import Cron from "react-cron-generator";
import {Button} from "../catalyst/button";
import {Spinner} from "@kalecard/common";
import {useState} from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import {auth} from "../../config/firebase";
import {useMutation} from "@apollo/client";
import {CREATE_NOTIFICATION_CAMPAIGN} from "../../graphql/mutations";

interface SendNotificationsInputs {
    name: string;
    textMessage: string;
    targetType: string;
    targetQueryId: number;
    targetCreatorIds: string;
    scheduleType: string;
    cronExpression: string;
}

export default function ( {
    onSubmitModal
}) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [createNotificationCampaignMutation] = useMutation(CREATE_NOTIFICATION_CAMPAIGN);
    const [selectedTargetType, setSelectedTargetType] = useState<string>("QUERY");
    const [selectedScheduleType, setSelectedScheduleType] = useState<string>("ONCE");
    const [cronExpression, setCronExpression] = useState<string>('0 0 * * *');
    const { reset, register, handleSubmit, watch } = useForm<SendNotificationsInputs>({
        defaultValues: {
            name: '',
            textMessage: '',
            targetType: '',
            targetQueryId: null,
            targetCreatorIds: '',
            scheduleType: 'ONCE',
            cronExpression: '0 0 * * *'
        },
    });

    const name = watch("name");
    const textMessage = watch("textMessage");
    const targetCreatorIds = watch("targetCreatorIds");
    const targetQueryId = watch("targetQueryId");

    const onSubmit: SubmitHandler<SendNotificationsInputs> = async (data) => {
        setIsLoading(true);
        const tokenId = await auth.currentUser.getIdToken();
        try {
            await createNotificationCampaignMutation({
                variables: {
                    name: data.name,
                    textMessage: data.textMessage,
                    targetType: selectedTargetType,
                    targetQueryId: selectedTargetType === "QUERY" ? data.targetQueryId : null,
                    targetCreatorIds: selectedTargetType === "CREATOR_IDS"
                        ? data.targetCreatorIds.split("\n").map((id) => id.trim())
                        : [],
                    scheduleType: selectedScheduleType,
                    cronExpression: selectedScheduleType === 'RECURRING' ? cronExpression : null
                },
                context: {
                    headers: {
                        authorization: tokenId ? `Bearer ${tokenId}` : "",
                    },
                },
            });
        } catch (err) {
            setError(`Error: ${err.message}`);
        } finally {
            setIsLoading(false);
            onSubmitModal();
            reset();
        }
    }

    const formIsValid =
        !isLoading && name && textMessage &&
        ((selectedTargetType === "CREATOR_IDS" && targetCreatorIds) || (selectedTargetType === "QUERY" && targetQueryId));

    return (
        <div className="container mx-auto p-4">
            <Heading className="mb-4">Create Notification Campaign</Heading>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Fieldset>
                    <FieldGroup>
                        <Field>
                            <Label>Campaign Name</Label>
                            <Input
                                type="text"
                                {...register("name")}
                            />
                        </Field>
                        <Field>
                            <Label>Notification Text</Label>
                            <Textarea
                                {...register("textMessage")}
                                rows={6}
                            ></Textarea>
                        </Field>
                    </FieldGroup>

                    <Divider className="mt-6"/>

                    <RadioGroup value={selectedTargetType} onChange={setSelectedTargetType}>
                        <Legend className="font-large">Target Group</Legend>
                        <Text>Define the recipients of the campaign through a metabase query or list of creator IDs</Text>
                        <RadioField>
                            <Label>Metabase Query</Label>
                            <Radio color="indigo" value="QUERY" />
                        </RadioField>
                        <RadioField>
                            <Label>Creator Ids</Label>
                            <Radio color="indigo" value="CREATOR_IDS" />
                        </RadioField>
                    </RadioGroup>
                    {selectedTargetType === "QUERY" && (
                        <FieldGroup>
                            <Field>
                                <Label>Metabase Card ID</Label>
                                <Input
                                    type="number"
                                    {...register("targetQueryId")}
                                />
                            </Field>
                        </FieldGroup>
                    )}
                    {selectedTargetType === "CREATOR_IDS" && (
                        <FieldGroup>
                            <Field>
                                <Label>Creator IDs (New Line Separated)</Label>
                                <Textarea
                                    {...register("targetCreatorIds")}
                                    rows={6}
                                ></Textarea>
                            </Field>
                        </FieldGroup>
                    )}

                    <Divider className="mt-6"/>

                    <RadioGroup value={selectedScheduleType} onChange={setSelectedScheduleType} className="mb-4">
                        <Legend className="font-large">Schedule</Legend>
                        <Text>Campaigns can be configured to run once or on a schedule</Text>
                        <RadioField>
                            <Label>Once</Label>
                            <Radio color="indigo" value="ONCE" />
                        </RadioField>
                        <RadioField>
                            <Label>Recurring</Label>
                            <Radio color="indigo" value="RECURRING" />
                        </RadioField>
                    </RadioGroup>
                    {selectedScheduleType === 'RECURRING' && <div>
                        <Cron
                            onChange={(e, text) => {
                                setCronExpression(e)
                            }}
                            showResultText={true}
                            showResultCron={true}
                        />
                    </div>}
                    <div className="flex justify-end">
                        <Button
                            color="indigo"
                            type="submit"
                            disabled={!formIsValid}
                        >
                            Create Campaign
                        </Button>
                    </div>
                </Fieldset>
            </form>
            <div className="mt-2 flex justify-end">
                <p className="font-bold text-red-500">{error}</p>
            </div>
            {isLoading && (
                <div className="flex flex-wrap content-center justify-center">
                    <Spinner size="h-6 w-6"/>
                </div>
            )}
        </div>
    );
}